<template>
  <div>
    <div class="userinfo">
      <div class="title">
        <div class="title-left"></div>
        <div class="title-right">个人信息<a style="padding-left: 5px" @click="updateView()"><i
          class="el-icon-edit-outline"></i></a></div>
      </div>
      <el-card class="box-card" style="font-size: 12px">
        <el-row :gutter="20">
          <el-col :span="2" style="text-align: right">
            <div class="grid-content">
              <div>用户头像：</div>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">
              <div>
                <el-upload
                  class="avatar-uploader"
                  :http-request="fileUploadFunction"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess_1"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="imgurl.headimgurl" :src="imgurl.headimgurl" class="avatar"
                       style="width: 65px;height: 65px; object-fit: cover;">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">
              <div>账号：{{userInfo.account}}</div>
              <!--<div>账号状态：{{status(userInfo.status)}}</div>-->
              <div>负责人姓名：{{userInfo.manager}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div> 手机号：{{userInfo.mobile}}<a style="padding-left: 5px" @click="updatePhoneView()"><i
                class="el-icon-edit-outline"></i></a></div>
              <div> 邮箱：{{userInfo.email}}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div> 组织类型：{{userflag(userInfo.userflag)}}</div>
              <div> 地址：{{userInfo.address}}{{userInfo.detailedaddress}}</div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <div> 组织名称：{{userInfo.unitname}}</div>
              <div> 主营业务：{{userInfo.mainbusiness}}</div>
            </div>
          </el-col>
          <el-col :span="2" style="text-align: right">
            <div class="grid-content">
              <div> 营业执照：</div>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content">
              <div>
                <el-upload
                  class="avatar-uploader"
                  :http-request="fileUploadFunction"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess_2"
                  :before-upload="beforeAvatarUpload">
                  <img v-if="imgurl.businesslicense" :src="imgurl.businesslicense" class="avatar"
                       style="width: 65px;height: 79px; object-fit: cover;">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="dataStatistics">
      <div class="title">
        <div class="title-left"></div>
        <div class="title-right">数据统计</div>
      </div>
    </div>

    <el-dialog title="修改个人信息" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :model="dataForm" label-position="left" label-width="100px"
               style="width: 70%; margin-left:50px;">
        <el-form-item label="组织类型">
          <el-select v-model="dataForm.userflag" placeholder="请选择组织类型" clearable
                     style="width: 100%">
            <el-option label="个人" :value="1"></el-option>
            <el-option label="企业" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织名称">
          <el-input v-model="dataForm.unitname" type="text" placeholder="请输入组织名称"/>
        </el-form-item>
        <el-form-item label="负责人姓名">
          <el-input v-model="dataForm.manager" type="text" placeholder="请输入负责人姓名"/>
        </el-form-item>
        <el-form-item label="联系邮箱">
          <el-input v-model="dataForm.email" type="text" placeholder="请输入邮箱"/>
        </el-form-item>
        <el-form-item label="经营地址">
          <el-cascader :options="options" v-model="dataForm.addressArray"
                       style="width: 100%"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="dataForm.detailedaddress" type="text" placeholder="请输入详细地址"/>
        </el-form-item>
        <el-form-item label="主营业务">
          <el-input v-model="dataForm.mainbusiness" type="textarea" rows="3" placeholder="请输入主营业务"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改手机号" :visible.sync="updatePhoneVieVisible" width="400px">
      <el-form autocomplete="off" :model="smsForm" ref="smsForm" :rules="smsFormRules" label-position="left" label-width="100px">
        <el-form-item prop="phone" label="新手机号：">
          <el-input placeholder="输入手机号" v-model="smsForm.phone"></el-input>
        </el-form-item>
        <el-form-item prop="code" label="验证码：">
          <el-input placeholder="请输入验证码" v-model="smsForm.code">
            <el-button @click="captchaConfig.codeTimer.onSendCodeClick('smsForm')"
                       :disabled="captchaConfig.codeTimer.disabled"
                       slot="append">
              {{ captchaConfig.codeTimer.text }}
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatePhoneVieVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="updatePhoneData()">
          确认修改
        </el-button>
      </div>
    </el-dialog>
    <el-dialog class="captcha-dialog" center :visible.sync="captchaConfig.captcha.show">
      <div class="captcha" ref="captcha"></div>
    </el-dialog>
  </div>
</template>
<script>
  import validator from '@/resources/js/async-validator'
  import {sendPhoneCode} from "@/apis/isrsms";
  import {getUserInfo, updateUserInfo, getAddressList, updatePhone} from '@/apis'

  require('@/resources/libs/jigsaw/jigsaw.min')
  export default {
    name: "userinfo",
    components: {
      simpDragCaptcha: () => import('@/components/common/SimpDragCaptcha'),
    },
    data() {
      return {
        dialogFormVisible: false,
        updatePhoneVieVisible: false,
        options: null,
        dataForm: {
          userflag: '',
          unitname: '',
          email: '',
          address: '',
          detailedaddress: '',
          addressArray: [],
          mainbusiness: '',
          manager: '',
          auid: ""
        },
        userInfo: {
          realname: '',
          account: '',
          userflag: '',
          manager: '',
          email: '',
          address: '',
          detailedaddress: '',
          mainbusiness: '',
          headimgurl: '/images/robot.jpg',
          businesslicense: '/images/robot.jpg',
          status: null,
          mobile: '',
          unitname: '',
          managerid: null
        }, imgurl: {
          headimgurl: '/images/robot.jpg',
          businesslicense: '/images/robot.jpg'
        }, smsFormRules: {
          phone: [
            {required: true, message: '手机号不能为空', trigger: 'blur'},
            {validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur'}
          ],
          code: [
            {required: true, message: '验证码不能为空', trigger: 'blur'},
            {min: 6, max: 6, message: '密码长度为6位', trigger: 'blur'},
          ]
        }, smsForm: {
          phone: '',
          auid: '',
          code: '',
          usertype : 0,
        },
        captchaConfig: {
          codeTimer: {
            disabled: false,
            time: 60,
            currentTime: 60,
            text: '获取验证码',
            onSendCodeClick: (formName) => {
              console.log(this.smsForm.phone)
              if (this.smsForm.phone == this.userInfo.mobile) {
                this.$message.warning("该手机号与当前绑定的手机号一致！")
                return false;
              }
              this.captchaConfig.codeTimer.sendCode(formName);
            },
            sendCode: (formName) => {
              this.$refs[formName].validateField('phone', valid => {
                if (valid == '') {
                  this.captchaConfig.captcha.initCaptcha()
                    .then(() => {
                      this.captchaConfig.codeTimer.doSendCode();
                    })
                }
              });
            },
            start: () => {
              let {disabled, currentTime} = this.captchaConfig.codeTimer;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.disabled = true;
              this.captchaConfig.codeTimer.timer = setInterval(() => {
                if (currentTime <= 1) {
                  this.captchaConfig.codeTimer.stop();
                } else {
                  --currentTime;
                  this.captchaConfig.codeTimer.text = `${currentTime}秒后重试`;
                }
              }, 1000);
            },
            stop: () => {
              if (this.captchaConfig.codeTimer.timer) {
                clearInterval(this.captchaConfig.codeTimer.timer);
              }
              this.captchaConfig.codeTimer.currentTime = this.captchaConfig.codeTimer.time;
              this.captchaConfig.codeTimer.text = '重新获取验证码';
              this.captchaConfig.codeTimer.disabled = false;
            },
            doSendCode: () => {
              let {disabled} = this.captchaConfig.codeTimer;
              let phone = this.smsForm.phone;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.start();
              sendPhoneCode({
                phone: phone
              }).then(res => {
                this.captchaConfig.codeTimer.start();
                this.$message.success('发送验证码成功，请注意查收！');
              }).catch(err => {
                this.captchaConfig.codeTimer.stop();
              });
            },
          },
          captcha: {
            show: false,
            initCaptcha: () => {
              return new Promise(resolve => {
                if (!this.captchaConfig.captcha.show) {
                  this.captchaConfig.captcha.show = true;
                }
                this.$nextTick(() => {
                  this.$refs.captcha.innerHTML = '';
                  window.jigsaw.init({
                    el: this.$refs.captcha,
                    onSuccess: () => {
                      this.$refs.captcha.innerHTML = '';
                      this.captchaConfig.captcha.show = false;
                      resolve();
                    },
                    onFail: () => {
                    },
                    onRefresh: () => {
                    }
                  })
                })
              })
            },
          }
        }
      }
    }
    , created() {
      this.getUserinfo();
      this.getAddressList();
    }
    ,
    methods: {
      updateUserInfo() {
        updateUserInfo(this.userInfo).then(res => {
          this.$message.success(res.retDesc);
        });
      }
      ,
      getUserinfo() {
        var adminuid = this.user.userId;
        getUserInfo({adminuid}).then(res => {
          this.userInfo = res.retdata;
          if (res.retdata.address) {
            this.dataForm.addressArray = res.retdata.address.split("/");
          }
          this.imgurl.headimgurl = res.retdata.headimgurl;
          this.imgurl.businesslicense = res.retdata.businesslicense;
        })
      }
      ,
      getAddressList() {
        getAddressList().then(res => {
          this.options = res.retdata;
        })
      }
      ,
      handleAvatarSuccess_1(res, file) {
        this.userInfo.headimgurl = res.src;
        this.imgurl.headimgurl = URL.createObjectURL(file.raw);
        this.updateUserInfo();
      }
      ,
      handleAvatarSuccess_2(res, file) {
        this.userInfo.businesslicense = res.src;
        this.imgurl.businesslicense = URL.createObjectURL(file.raw);
        this.updateUserInfo();
      }
      ,
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
      ,
      userflag(flag) {
        if (flag == 1) {
          return "个人";
        } else {
          return "企业";
        }
      }
      ,
      status(status) {
        if (status == 0) {
          return "正常";
        } else {
          return "禁用";
        }
      }
      ,
      updateView() {
        this.dialogFormVisible = true;
        this.dataForm = {
          userflag: this.userInfo.userflag,
          unitname: this.userInfo.unitname,
          email: this.userInfo.email,
          address: this.userInfo.address,
          detailedaddress: this.userInfo.detailedaddress,
          mainbusiness: this.userInfo.mainbusiness,
          manager: this.userInfo.manager,
          managerid: this.userInfo.managerid
        }
        if(this.dataForm.address != null){
          this.dataForm.addressArray = this.dataForm.address.split("/");
        }

      }
      ,
      updatePhoneView() {
        this.updatePhoneVieVisible = true;
      }
      ,
      updateData() {
        if(this.dataForm.addressArray != null){
          let addressArray = this.dataForm.addressArray;
          let address = "";
          addressArray.filter((item, index) => {
            if (index == 0) {
              address = item;
            } else {
              address += "/" + item;
            }
          });
          this.dataForm.address = address;
          this.userInfo.address = address;
        }
        updateUserInfo(this.dataForm).then(res => {
          this.$message.success(res.retDesc);
          this.getUserinfo();
          this.dialogFormVisible = false;
        });
      }, updatePhoneData() {
        this.smsForm.auid=this.user.userId;
        updatePhone(this.smsForm).then(res => {
          this.$message.success(res.retDesc);
          this.getUserinfo();
          this.updatePhoneVieVisible = false;
        });
      }
    }
  }
</script>

<style>
  .title-right {
    font-size: 15px;
    margin-left: 10px;
    line-height: 15px;
    font-weight: 600;
  }

  .title-left {
    width: 2px;
    height: 15px;
    background: red;
  }

  .title {
    display: flex;
    margin: 10px 0px;
  }

  .box-card {
    font-size: 14px;
    line-height: 40px;
  }

  .userinfo .filter-item {
    margin-left: 5px;
  }

  .userinfo .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .userinfo .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .userinfo .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }

  .userinfo .avatar {
    width: 50px;
    height: 50px;
    display: block;
  }
</style>
